@import 'Normalize.css';
@import 'Media.css';

:root {
  --container-padding: 20px;
  --footer-background-color: var(--color-primary-700);
}

* {
  box-sizing: border-box;
}

body {
  max-width: 100vw;
  max-height: 100vh;
  font-family: var(--font-family-system), 'Objektiv', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-mobile-only] {
  @media (--tablet-s) {
    display: none;
  }
}
[data-desktop-only] {
  @media (--mobile) {
    display: none;
  }
}

:where(h2) {
  font-size: 24px;
  letter-spacing: -0.5px;

  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);

  @media (--tablet) {
    font-size: 32px;
    letter-spacing: -0.5px;
  }
}

.lockScroll {
  overflow: hidden;
}

.noScrollAccordion {
  overflow: hidden;
  @media (--tablet-l) {
    overflow: auto;
  }
}

.appWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.containerWrapper {
  height: calc(100vh - 112px);
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-width: 1023px) {
  .container {
    padding: 0;
  }
}

.checkoutContainer {
  margin-top: 56px !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.driftrock .driftrock-launcher-trigger {
  bottom: 58px !important;

  @media (min-width: 776px) {
    bottom: 106px !important;
  }

  @media (--tablet-l) {
    bottom: 0 !important;
  }

  [data-has-footer-sticky='true'] & {
    bottom: 52px;
    @media (--tablet-l) {
      bottom: 0;
    }
  }
}

[data-service-consumer='native'] {
  --pdpTopAdsOffset: 0;
  /* disable tap highlight completely */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: manipulation;
  -webkit-overflow-scrolling: touch;
  opacity: 1;

  transition: 0.2s;
  transition-delay: 0.01s;

  @starting-style {
    opacity: 0;
  }

  &[data-is-ready] {
    opacity: 1;
  }
  /* disable touch callout on images */
  img {
    -webkit-touch-callout: none;
  }

  /* disable touch callout on links */
  a {
    -webkit-touch-callout: none;
  }

  /* disable touch callout on buttons */
  button {
    -webkit-touch-callout: none;
  }

  & * {
    touch-action: manipulation;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
  }
}

[data-page-theme='heywatt'] {
  & h2 {
    color: var(--color-secondary-700);
  }
}

body {
  & .showHeywatt {
    display: none;
  }
}

body:has([data-page-theme='heywatt']) {
  & .showHeywatt {
    display: flex;
  }

  & .hideHeywatt {
    display: none;
  }
}
